import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/Layout"
import SEO from "../components/SEO"

import Container from "../components/Container/Container"

import { FiLink } from "react-icons/fi"

const ONas = () => {
  const { action1a, action2, action3 } = useStaticQuery(
    graphql`
      query {
        action1a: file(relativePath: { eq: "o-nas/action1a.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 816) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        action2: file(relativePath: { eq: "o-nas/action2.jpeg" }) {
          childImageSharp {
            fluid(maxWidth: 816) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        action3: file(relativePath: { eq: "o-nas/action3.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 816) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `
  )

  return (
    <Layout pageInfo={{ pageName: "o-nas" }}>
      <SEO title="O nas" />
      <Container>
        <div className="about-us">
          <div className="about-us__text">
            <div>
              <p>
                Zajmujemy się dystrybucją płytowych wymienników ciepła produkcji
                Alfa Laval. Specjalizujemy się w przemyśle ciepłowniczym,
                chłodniczym i odzysku ciepła.
              </p>
              <p>
                Firma EQTHERM działa głównie na terenie województwa
                mazowieckiego i łódzkiego, gdzie współpracujemy między innymi z
                wykonawcami instalacji chłodu/ciepła w inwestycjach biurowych,
                mieszkalnych oraz przeznaczenia publicznego, hurtowniami
                instalacyjnymi, projektantami instalacji sanitarnych,
                instalatorami, działami technicznymi i utrzymania ruchu firm
                przemysłowych.
              </p>
              <p>
                <strong>Zakres naszej działalności obejmuje:</strong>
              </p>
              <ul>
                <li>dobór techniczny płytowych wymienników ciepła</li>
                <li>
                  wsparcie techniczne klienta w zakresie płytowych wymienników
                  ciepła Alfa Laval
                </li>
                <li>
                  wycenę i dostarczanie części zamiennych (uszczelki, płyty,
                  środki czyszczące) do wymienników płytowych Alfa Laval
                </li>
                <li>
                  wycenę i dostarczanie części zamiennych do wymienników
                  płytowych, takich jak: uszczelki, płyty, środki czyszczące
                </li>
                <li>
                  udzielanie informacji klientom na temat bieżących stanów
                  magazynowych urządzeń katalogowych
                </li>
                <li>wsparcie i nadzór realizowanych dostaw</li>
                <li>
                  udzielanie dokumentacji technicznej do dostarczonych urządzeń
                </li>
              </ul>
            </div>

            <p>W naszej ofercie znajdziecie Państwo:</p>
            <ul>
              <li>
                <strong>
                  <Link to="/produkty/wymienniki-cb">
                    Płytowe lutowane wymienniki ciepła serii CB
                  </Link>
                </strong>
              </li>
              <li>
                <strong>
                  <Link to="/produkty/alfa-nova">
                    Wymienniki AlfaNova ze stali kwasoodpornej
                  </Link>
                </strong>
              </li>
              <li>
                <strong>
                  <Link to="/produkty/wymienniki-gphe">
                    Płytowe uszczelkowe wymienniki ciepła
                  </Link>
                </strong>
              </li>
            </ul>

            <p>
              Oferowane przez nas wymienniki ciepła znajdują zastosowanie między
              innymi:
            </p>
            <ul>
              <li>
                W instalacjach centralnego ogrzewania i ciepłej wody użytkowej w
                obiektach mieszkalnych oraz komercyjnych
              </li>
              <li>Instalacjach chłodzenia i klimatyzacji</li>
              <li>
                Przemysłowych procesach grzania i chłodzenia ( ogrzewanie,
                chłodzenie, odzysk ciepła, kondensacja, odparowywanie)
              </li>
              <li>Instalacjach solarnych</li>
              <li>
                Instalacjach wykorzystujących ciepło odpadowe z procesów
                technologicznych
              </li>
              <li>Systemach wykorzystujących wody geotermalne</li>
            </ul>

            <p className="about-us__paragraph--centered">
              <Link to="/produkty">
                <FiLink />
                SPRAWDŹ NASZĄ OFERTĘ I DOWIEDZ SIĘ WIĘCEJ
              </Link>
              <br />
              lub
              <br />
              <Link to="/kontakt">
                <FiLink />
                SKONTAKTUJ SIĘ Z NAMI PRZEZ FORMULARZ KONTAKTOWY
              </Link>
            </p>
          </div>

          <div className="about-us__images">
            <div className="about-us__image">
              <Img
                fluid={action3.childImageSharp.fluid}
                alt="Wymiennik ciepła skręcany uszczelkowy GPHE w EQTHERM"
              />
            </div>
            <div className="about-us__image">
              <Img
                fluid={action1a.childImageSharp.fluid}
                alt="EQTHERM przy pracy"
              />
            </div>
            <div className="about-us__image">
              <Img
                fluid={action2.childImageSharp.fluid}
                alt="Wymienniki ciepła skręcane uszczelkowe GPHE w EQTHERMM"
              />
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export default ONas
